/* =============================================================================
Hero Block
============================================================================= */
@import "styles/marketing/variables.scss";

:global(.marketing) .hero {
  width: 100%;
  padding: 3.25rem 0 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  
  @include for-tablet-landscape-up {
    padding: 4rem 0 4rem;
  }

  /* BG and BG Gradient
  =================================================== */
  .bg-image {
    top: 0;
    margin-top: 2rem;
    max-height: 50rem;
    object-fit: fit;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    visibility: hidden;

    @include for-tablet-landscape-up {
      visibility: visible;
    }
  }

  .bg-gradient {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
    background: radial-gradient(34.27% 53.53% at 81.53% 41.09%, #D8E7FF 0%, rgba(222, 235, 255, 0.13) 100%), #EFF6FF;
  }

  /* Content
  =================================================== */
  .top-layout {
    display: flex;
    flex-direction: column;
    position: static;
    flex-wrap: nowrap;
    justify-content: space-between;
    
    @include for-tablet-landscape-up {
      flex-direction: row;
      align-items: center;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 100%;
    position: relative;

    @include for-tablet-landscape-up {
      width: 55%;
      max-width: 659px;
      padding-bottom: 4rem;
    }

    @media #{$desktop} {
      width: 75%;
    }
  }

  .hero-image {
    object-fit: contain;
    height: auto;
    align-self: flex-end;
    margin-top: 2rem;
    z-index: 2;

    @media screen and (max-width: 1023px) {
      width: calc( 100% + 2rem);
      position: relative;
      left: 2rem;
      max-width: 700px;
    }
    
    @include for-tablet-landscape-up {
      position: absolute;
      right: 0;
      top: 6rem;
      max-width: 500px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 600px;
    }
    @media screen and (min-width: 1600px) {
      max-width: 700px;
    }
  }

  @media only screen and (max-width: $tablet-min-width) {
    .top-layout {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
    }

    .content-section {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  .subhead {
    color: $primary-color;
    font-size: $subhead-font-size;
    font-weight: 500;

    @media #{$phone} {
      font-size: 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  .title {
    color: $secondary-color;
    font-weight: 700;

    @media #{$phone} {
      font-size: 1.75rem;
      margin-bottom: 1rem;
    }

    @media #{$tablet} {
      font-size: 2.5rem;
    }

    @media #{$desktop} {
      font-size: 4.125rem;
    }
  }

  .description {
    color: $secondary-color;
    font-size: 1.25rem;
    margin-bottom: 3rem;
    max-width: 588px;

    @media #{$phone} {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  }

  .buttons-section {
    display: flex;
    align-items: center;
  }

  .demo-btn {
    margin-right: 2rem;
  }


  /* Admin login card
  =================================================== */
  .card {
    background-color: white;
    border-radius: $card-radius;
    padding: 2rem;
    margin: 4rem auto 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 2;

    @include for-phone-only {
      margin: 0 auto;
    }
    
    @include for-tablet-portrait-up {
      flex-direction: row;
      align-items: center;
    }
  }

  .card-title {
    font-size: 1.75rem;
    color: $secondary-color;
  }

  .card-description {
    max-width: 500px;
    width: auto;
    color: $secondary-color;
    margin-bottom: 1rem;
    
    @include for-tablet-portrait-up {
      padding-right: 1rem;
      margin-bottom: 0;
    }
  }

  /* Notification bar
  =================================================== */
  .notification-card {
    background-color: $brand-color-4;
    border-radius: $card-radius;
    padding: 1rem 2rem;
    margin: 2rem auto 0;
    color: $secondary-color;
    position: relative;
    z-index: 2;
  }
}
