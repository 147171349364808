/* =============================================================================
Book a demo block
============================================================================= */
@import "styles/marketing/variables.scss";

:global(.marketing) .wrapper {
    background: $brand-color-3;
    padding: 5rem 0 6rem;

    @include for-tablet-portrait-up {
        padding: 6rem 0 7rem;
    }

    @include for-tablet-landscape-up {
        padding: 10.5rem 0 9rem;
    }

    .container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        @include for-tablet-portrait-up {
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }

    /* Blue Panel
    =================================================== */
    .panels {
        width: 100%;
        max-width: 320px;
        margin: 0 0 4rem;
        padding: 0 20px 20px 0;
        
        @include for-tablet-portrait-up {
            margint: 0 3rem 0 0;
        }
        
        @include for-tablet-landscape-up {
            max-width: 486px;
            padding: 0 40px 40px 0;
        }
    }

    .panel {
        background: $brand-color-2;
        padding: 2rem 2rem 4rem;
        border-radius: 10px;
        box-shadow: 20px 20px 0 0 $brand-color-4;

        @include for-tablet-portrait-up {
            max-width: calc(100% - 40px);
        }
        
        @include for-tablet-landscape-up {
            max-width: 416px;
            padding: 2.75rem 2.75rem 7rem;
            box-shadow: 40px 38px 0 0 $brand-color-4;
        }
    }

    .panel-title,
    .panel-caption {
        color: $white;
    }

    .panel-title {
        text-transform: uppercase;
        opacity: 0.5;
        font-weight: 500;
    }

    .panel-caption {
        line-height: 1.6;
        font-size: 1.175rem;
        
        @include for-tablet-landscape-up {
            font-size: 1.75rem;
        }
    }

    /* Form
    =================================================== */
    .form {
        width: 100%;
        max-width: 486px;

        .form-error {
            margin-bottom: 1rem;
        }

        @include for-desktop-up {
            margin-right: 7rem;
        }

        :global(.form-row) :global(.input-component),
        :global(.form-row) :global(.select-wrap) {
            width: 100%;
        }

        :global(.select) {
            background-position: 97% 50%;
        }

        .form-submit {
            width: 100%;
            margin: 1rem 0 0;
        }
    }

    .success {
        max-width: 400px;
        margin: 2rem auto;
        font-size: 2rem;
        line-height: 1.6;
    }
}