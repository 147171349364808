/* =============================================================================
Why Sigma Block
============================================================================= */
@import "styles/marketing/variables.scss";

:global(.marketing) .wrapper {
    background: $brand-color-2;
    color: $white;
    padding: 4rem 0 6rem;
    overflow: hidden;
    
    @include for-tablet-portrait-up {
        padding: 6.625rem 0 8.75rem;
    }

    .logo {
        height: auto;
        margin-bottom: 2.25rem;
        filter: grayscale(1) brightness(10);
        width: 142px;

        @include for-tablet-portrait-up {
            width: 181px;
        }
    }

    .main-grid,
    .columns {
        display: flex;
        flex-flow: row wrap;

        @include for-tablet-portrait-up {
            flex-wrap: nowrap;
        }
    }

    /* Main section
    =================================================== */
    .main {
        
        &-grid {

            @include for-phone-only {
                margin: 0 0 6.5rem;
            }
            
            @include for-tablet-landscape-up {
                margin: 0 0 6.5rem;
                justify-content: space-between;
            }
        }

        &-content {
            flex: 1 1 100%;
            padding: 0 0 5rem;

            @include for-tablet-landscape-up {
                padding: 3rem 0 0;
                width: 50%;
                flex-basis: 50%;
                max-width: 461px;
            }
        }

        &-title {
            color: $white;
            font-size: 35px;
            max-width: 423px;
        }

        &-description {
            margin-bottom: 2rem;
        }

        &-actions {
            display: flex;
            align-items: center;

            .demo-btn {
                margin-right: 2rem;
            }

            .learn-more {
                color: $white;

                &:hover {
                    color: $brand-color-1;
                }
            }
        }

        &-image {
            width: 100%;

            @include for-tablet-portrait-up {
                width: 50%;
                max-width: 530px;
            }

            @media #{$tablet} {
                position: absolute;
                right: -4rem;
                top: 0;
                transform: translateY(140%);
                width: calc(50% + 2rem);

            }
        }
    }

    /* Columns
    =================================================== */
    .columns {
        justify-content: space-between;

        @media #{$tablet} {
            max-width: 50%;
            flex-wrap: wrap;
        }
    }

    .column {
        &:not(:last-child) {
            margin-bottom: 4rem;
        }

        
        @include for-tablet-portrait-up {
            max-width: 281px;
        }

        @include for-tablet-landscape-up {
            margin-bottom: 0;
        }

        @media #{$tablet} {
            flex: 1 1 100%;
        }

        &-title {
            color: $white;
            font-size: 16px;
            font-weight: 700;
        }

        &-description {
            font-size: 14px;
        }
    }
}